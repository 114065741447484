/**
 * Copyright © Zaproo. All rights reserved.
 */
define([
    'jquery',
    'js/bootstrap/popper.min',
    'js/bootstrap/anchor.min',
    'domReady!'
], function ($, popper) {
    'use strict';

    window.Popper = popper;

    function initScroll () {
        $('a[href*="#"]')
            .not('[href="#"]')
            .not('[href="#TODO"]')
            .not('[data-toggle]')
            .not('a[href*="#products-list"]')
            .click(function (event) {
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                    if (target.length) {
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 600, function () {
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) {
                                return false;
                            } else {
                                $target.focus();
                            }
                        });
                    }
                }
            });
    }

    function setPosition() {
        if (!$('[data-vd-position]').length) {
            return;
        }

        // Don't position if window width is smaller than Bootstrap lg
        if ($(window).width() < 992) {
            return;
        }
        var relative = $('[data-vd-position="relative-to-this"]');
        var container = $('[data-vd-position="container"]');
        var content = $('[data-vd-position="content"]');

        var top = 0;
        var left = '100%';
        var right = ($(window).width() - (relative.offset().left + relative.outerWidth())) * -1;
        var min_height = $(window).height() - relative.offset().top;

        container.css({'top': top, 'right': right, 'bottom': 0, 'left': left});
        content.css({'min-height': min_height, 'top': relative.offset().top});
        relative.closest('.row').css({'min-height': content.outerHeight()});
    }

    function transformPosition() {
        // Trigger function if [data-vd-transform] element exists on page
        // Trigger function if window width is smaller than Bootstrap lg
        if ($('[data-vd-transform]').length && $(window).width() < 992) {
            var window_height = $(window).height(),
                document_scrolled = $(document).scrollTop();
    
            for (var i = 0; i < $('[data-vd-transform]').length; i++) {
    
                // Don't transform element if screen is really sort and user hasn't scrolled
                if (window_height < 500 && document_scrolled < 250) {
                    return;
                }
    
                // Only transform element that is inside active tab-pane
                var tabPane = $('[data-vd-transform]')[i].closest('.tab-pane');
                if (tabPane && tabPane.classList.contains('active')) {
                    var $this = $($('[data-vd-transform]')[i]),
                        $this_side = $this.offset().left,
                        $this_container = $this.find('.transform-container'),
                        $this_container_height = $this_container.height(),
                        $this_top = $this.offset().top;
                        
                    if (window_height + document_scrolled < $this_top + $this_container_height) {
                        $this_container.addClass('moved');
                        $this_container.css({'left': $this_side+'px', 'right': $this_side+'px'});
                    } else {
                        $this_container.removeClass('moved');
                    }
                }
            }
        }
    }

    window.transformPosition = transformPosition;
    require(["js/bootstrap/bootstrap"]);
    initScroll();
    setPosition();
    transformPosition();

    $(window).resize(function () {
        setPosition();
    });

    $(window).scroll(function () {
        setPosition();
        transformPosition();
    });
});
